import React from "react"
import styled from "styled-components"
import ImageCity from "../../images/svg/inline-svg/image-city_chicago.svg"

const SectionArriveCity = ({ data }) => (
  <section>
    <div className="grid-container">
      <div className="grid-x grid-padding-x align-center">
        <StyledImageItineraryContainer>
          <ImageCity />
        </StyledImageItineraryContainer>
        <div className="small-12 medium-10 cell">
          <ItineraryGridContainer>
            <div className="grid-x grid-padding-x">
              <ItineraryDetails>
                <p>
                  <strong>Attending WebPT Pre-Con?</strong>
                </p>
                <p>Arrive Wednesday, September 11</p>
              </ItineraryDetails>
              <ItineraryDetails>
                <p>
                  <strong>Thursday, September 12</strong>
                </p>
                <p>WebPT Workshops</p>
              </ItineraryDetails>
              <ItineraryDetails>
                <p>
                  <strong>Friday, September 13</strong>
                </p>
                <p>Ascend Day 1 Kickoff Sessions and Ascend Party</p>
              </ItineraryDetails>
              <ItineraryDetails>
                <p>
                  <strong>Saturday, September 14</strong>
                </p>
                <p>Ascend Day 2 Programming Ends at 4:00 PM</p>
              </ItineraryDetails>
            </div>
          </ItineraryGridContainer>
        </div>
      </div>
    </div>
  </section>
)

export default SectionArriveCity
const ItineraryGridContainer = styled.div.attrs({
  className: "grid-container",
})`
  margin-top: 30px;
  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
`
const ItineraryOverview = styled.div.attrs({
  className: "small-12 large-2 cell",
})`
  @media screen and (max-width: 1024px) {
    margin-top: 30px;
  }

  p {
    font-weight: 400;
    font-size: 32px;
    line-height: 130%;
    @media screen and (max-width: 1024px) {
      font-size: 22px;
    }
  }
`
const ItineraryDetails = styled.div.attrs({
  className: "small-12 large-3",
})`
  p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
  }

  strong {
    font-weight: 700;
    text-transform: uppercase;
  }

  @media screen and (max-width: 1024px) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
`
const StyledImageItineraryContainer = styled.div.attrs({
  className: "small-12 medium-10 cell",
})`
  svg {
    max-width: 100%;
    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 640px) {
      height: 80px;
    }
  }
`
