import React from "react"
import SectionCurrentSponsors from "../components/sections/sectionSponsorCurrent"
import HeadingPastSpnsrs from "../images/svg/inline-svg/heading_past-spnsrs.svg"
import HeadingPastSpnsrsMobile from "../images/svg/inline-svg/heading_past-spnsrs_mobile.svg"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionFeaturedSpeakers from "../components/sections/featuredSpeakers"
import SectionQuoteBox from "../components/sections/quoteBox"
import { graphql, StaticQuery } from "gatsby"
import SectionMediaHalf from "../components/sections/mediaHalf"
import SectionIconStats from "../components/sections/iconStats"
import IconThumbsup from "../images/svg/inline-svg/icon-thumbsup.svg"
import IconLocation from "../images/svg/inline-svg/icon-location.svg"
import IconGroup from "../images/svg/inline-svg/icon-group.svg"
import IconSpeakers from "../images/svg/inline-svg/icon-speakers.svg"
import SectionArriveCity from "../components/sections/sectionArriveCity"
import SectionImageCollage from "../components/sections/sectionImageCollage"
import SectionHeroPage from "../components/sections/heroPage"
import LogoAscendText from "../images/svg/inline-svg/logo-ascendtext.svg"
import Cityscape from "../images/svg/inline-svg/image-city.svg"
import AscendTextMobile from "../images/svg/inline-svg/logo-ascendtext-mobile.svg"

const quoteBoxQuotes = [
  {
    quote:
      "One of the things that I would say that I think Ascend does better than anyone else is providing time for people to truly network and get into conversations.",
    authorName: "Eric Fernandez",
    authorCredentials: "",
    authorTitle: "Director of Rehab",
    authorCompany: "Hyperice",
  },
  {
    quote:
      "Ascend is a meeting of the minds. It’s about getting to know what other practices are doing and to know that you're not alone in some of the daily struggles and how to improve those things all around.",
    authorName: "Gyn Stackhouse",
    authorCredentials: "",
    authorTitle: "Director of Admin Operations",
    authorCompany: "Rehab 2 Perform",
  },
  {
    quote: "The networking here at Ascend is incredible",
    authorName: "Katie Jarvis",
    authorCredentials: "",
    authorTitle: "Public Relations Director",
    authorCompany: "Dynamix Physical Therapy",
  },
  {
    quote:
      "I think Ascend has raised the bar in terms of expectations for what a conference should be. The content is progressive and forward thinking. Not just where we are today or maybe next year, where are we five years from now, ten years from now?",
    authorName: "Stephen Huntsman",
    authorCredentials: "",
    authorTitle: "Chief Compliance Officer",
    authorCompany: "Upstream Rehabilitation",
  },
  {
    quote:
      "As groups of practitioners, you find this nexus between where you are and what's possible. And that is the value of Ascend.",
    authorName: "Kendell Jno-Finn",
    authorCredentials: "PT, DPT",
    authorTitle: "Founder and President",
    authorCompany: "M3 Performance Physical Therapy",
  },
]

const IndexPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              location
              date
              startMonth
              endMonth
              startDay
              endDay
              startTime
              endTime
              startDate
              endDate
              year
            }
          }
          dummyImage: file(relativePath: { eq: "dummy/dummy-600X342.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 608) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          headerImageMobile: file(
            relativePath: { eq: "headers/hero-home-mobile.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 624) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          headerImage: file(relativePath: { eq: "headers/hero-home.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 712) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          reserveYourRoom: file(relativePath: { eq: "ReserveYourRoom.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 504) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          big10: file(relativePath: { eq: "Big10.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktop: file(
            relativePath: {
              eq: "background/202002_Ascend_Website_Images_Hero_Homepage.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const dummyImage = data.dummyImage.childImageSharp.fluid
        const headerImage = data.headerImage.childImageSharp.fluid
        const headerImageMobile = data.headerImageMobile.childImageSharp.fluid
        // const big10 = data.big10.childImageSharp.fluid
        // const ReserveYourRoom = data.reserveYourRoom.childImageSharp.fluid
        const year = data.site.siteMetadata.year
        const date = data.site.siteMetadata.date
        const location = data.site.siteMetadata.location
        const iconStatsArray = [
          {
            icon: <IconThumbsup />,
            statTitle: "9.5/10",
            statDescription: "Average attendee rating",
            fontSize: 32,
          },
          {
            icon: <IconLocation />,
            statTitle: "50",
            statDescription: "States represented",
            fontSize: 32,
          },
          {
            icon: <IconGroup />,
            statTitle: "450+",
            statDescription:
              "Private practice owners, rehab therapy executives, therapists, and support staff",
            fontSize: 16,
          },
          {
            icon: <IconSpeakers />,
            statTitle: "25",
            statDescription:
              "Keynote speakers, thought leaders, and rehab therapy influencers",
            fontSize: 16,
          },
        ]
        return (
          <Layout>
            <Seo
              title={`Ascend ${year}`}
              location={`https://ascendevent.com/`}
              description={`Ascend ${year} is the ultimate rehab therapy business summit. ${date} in ${location}.`}
            />

            <SectionHeroPage
              eyebrow={`${date}`}
              heading="Get Ready for Ascend:"
              subheading="The Ultimate Rehab Therapy Business Summit"
              ancillaryText1="Marriott Marquis Chicago McCormick Place"
              ancillaryText2="2121 S Prairie Ave, Chicago, IL 60616"
              image={headerImage}
              sectionHeading={<LogoAscendText />}
              textWidth={5}
              mediaMediumOffset={0}
              mediaMediumWidth={6}
              textAreaAccentImg={<Cityscape />}
              mobileSectionHeading={<AscendTextMobile />}
              mobileImage={headerImageMobile}
            />
            <SectionMediaHalf
              image={dummyImage}
              videoUrl="https://share.vidyard.com/watch/qPHh9UHMCwJXAMdpcDjZP6"
              showVideo={true}
              sectionHeading=""
              mediumOrder={1}
              smallOrder={1}
              textSmallOrder={2}
              textMediumOrder={2}
              textLargeOrder={2}
              textSmallOffset={0}
              textMediumOffset={0}
              textLargeOffset={1}
              textWidthMedium={5}
              imageWidthMedium={6}
              mediaSmallOffset={0}
              mediaMediumOffset={0}
              mediaLargeOffset={0}
              mediaSmallOrder={1}
              mediaMediumOrder={1}
              mediaLargeOrder={1}
              headingTag="h3"
              sectionBody="<h2>Why Attend?</h2><p>
                    At Ascend, exceptional education, world-class speakers, cutting-edge products, and powerful networking converge to deliver a one-of-a-kind industry event. Our goal? To pack a year’s worth of actionable insights into an unforgettable, two-day event aimed to help you better run your business, improve your bottom line, and excel as a therapy professional.
                  </p>
                  <p>
                    <strong>
                      Whatever your role—and whatever your company structure—if you’re a rehab therapy professional who wants to elevate your practice and profession, Ascend is for you.
                    </strong>
                  </p>"
            />
            <SectionIconStats sectionTitle="" iconStatArray={iconStatsArray} />
            <SectionFeaturedSpeakers headingTag="h3" />
            <SectionQuoteBox quotes={quoteBoxQuotes} />
            <SectionImageCollage />
            <SectionArriveCity data={data} />

            {/*            <SectionMediaHalf*/}
            {/*              image={ReserveYourRoom}*/}
            {/*              textSmallOffset={0}*/}
            {/*              textMediumOffset={1}*/}
            {/*              textLargeOffset={1}*/}
            {/*              textWidthMedium={6}*/}
            {/*              mediaSmallOffset={0}*/}
            {/*              mediaMediumOffset={0}*/}
            {/*              mediaLargeOffset={0}*/}
            {/*              mediaLargeOrder={1}*/}
            {/*              mediaMediumOrder={1}*/}
            {/*              mediaSmallOrder={1}*/}
            {/*              imageWidthMedium={5}*/}
            {/*              mediumOrder={1}*/}
            {/*              smallOrder={0}*/}
            {/*              sectionHeading=""*/}
            {/*              headingTag="h2"*/}
            {/*              sectionBody="<h3>Reserve your room at the Marriott Marquis Chicago McCormick Place.</h3><p><strong>2121 S Prairie Ave, Chicago, IL 60616</strong></p><p>*/}
            {/*Book your room at the Marriott Marquis Chicago by August 19, 2024, to lock in our special negotiated rate of $269/night. Simply mention that you’d like a room in the “Ascend room block” when making your reservation—or reserve your room online by clicking the button below.</p>*/}
            {/*<p><strong>And get entered to win a free night’s stay!</strong></p><p>*/}
            {/*Reserve your room as part of the Ascend room block and get automatically entered to win a one-night of your stay on us. We’ll announce the winner during the week of Ascend.</p><a href='https://book.passkey.com/e/50808723' target='_blank' class='button large'>Book Your Stay</a>"*/}
            {/*            />*/}
            <SectionCurrentSponsors />
          </Layout>
        )
      }}
    />
  )
}
export default IndexPage
