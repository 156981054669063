import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import SectionHeadingSvgCity from "../../images/svg/inline-svg/CHI.svg"
import SectionHeadingSvgCityMobile from "../../images/svg/inline-svg/CHI_mobile.svg"
import VerticalSectionTitle from "./verticalSectionTitle"
import Img from "gatsby-image"

const SectionImageCollage = ({ headingTag: HeadingTag = "p" }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          blockImage1: file(
            relativePath: { eq: "collages/location/WelcomeToChicago-01.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 166) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          blockImage2: file(
            relativePath: { eq: "collages/location/WelcomeToChicago-02.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 166) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          blockImage3: file(
            relativePath: { eq: "collages/location/WelcomeToChicago-03.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 166) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          blockImage4: file(
            relativePath: { eq: "collages/location/WelcomeToChicago-04.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 166) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          bannerImage: file(
            relativePath: { eq: "collages/location/WelcomeToChicago-00.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 712) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const blockImage1 = data.blockImage1.childImageSharp.fluid
        const blockImage2 = data.blockImage2.childImageSharp.fluid
        const blockImage3 = data.blockImage3.childImageSharp.fluid
        const blockImage4 = data.blockImage4.childImageSharp.fluid
        const bannerImage = data.bannerImage.childImageSharp.fluid

        return (
          <StyledSection>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <VerticalSectionTitle
                  svg={<SectionHeadingSvgCity />}
                  mobileSvg={<SectionHeadingSvgCityMobile />}
                />
                <div className="small-12 large-3 cell">
                  <SectionTitle>Welcome to Chicago!</SectionTitle>
                  <SectionSubtitle>
                    Ascend 2024 is heading to the windy city. As the third
                    largest city in the US, known for its awe-striking
                    architecture, die-hard sports fans, and award-winning foodie
                    scene, Chicago has a little something for everyone. Ascend
                    attendees will be in the heart of the South Side at the
                    Marriot Marquis Chicago—located just minutes from Lake
                    Michigan and landmarks like Grant Park and Soldier Field.
                  </SectionSubtitle>
                </div>
                <div className="small-12 large-7 large-offset-1 cell">
                  <div className="grid-container full">
                    <div className="grid-x">
                      <div className="small-12 collage-image collage-image-banner">
                        <a
                          href="https://www.choosechicago.com/"
                          rel="nofollow noreferrer noreferrer"
                          target="_blank"
                        >
                          <Img fluid={bannerImage} />
                        </a>
                      </div>
                      <CollageImageBlocksContainer>
                        <a
                          href="https://www.choosechicago.com/"
                          rel="nofollow noreferrer"
                          target="_blank"
                        >
                          <Img fluid={blockImage1} />
                        </a>
                        <a
                          href="https://www.choosechicago.com/"
                          rel="nofollow noreferrer"
                          target="_blank"
                        >
                          <Img fluid={blockImage2} />
                        </a>
                        <a
                          href="https://www.choosechicago.com/"
                          rel="nofollow noreferrer"
                          target="_blank"
                        >
                          <Img fluid={blockImage3} />
                        </a>
                        <a
                          href="https://www.choosechicago.com/"
                          rel="nofollow noreferrer"
                          target="_blank"
                        >
                          <Img fluid={blockImage4} />
                        </a>
                      </CollageImageBlocksContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StyledSection>
        )
      }}
    />
  )
}

export default SectionImageCollage
const StyledSection = styled.section`
  @media screen and (max-width: 640px) {
    padding: 30px 0;
  }
`

const SectionTitle = styled.h3`
  margin-bottom: 30px;
  @media screen and (max-width: 640px) {
    margin-top: 20px;
    font-size: 22px;
    line-height: 1.3;
  }
`
const SectionSubtitle = styled.p`
  @media screen and (max-width: 640px) {
    margin-top: 20px;
  }
`
const CollageImageBlocksContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;

  img {
    max-height: 221px;
    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 640px) {
      max-height: initial;
    }
  }

  a {
    display: contents;
  }

  div {
    width: 23%;
    margin-top: 30px;
    margin-right: 8px;

    &:last-of-type {
      margin-right: 0;
    }

    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 640px) {
      width: 47%;
    }
  }
`
