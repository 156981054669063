import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import SectionHeadingSvgSpkr from "../../images/svg/inline-svg/SPKR.svg"
import SectionHeadingSvgSpkrMobile from "../../images/svg/inline-svg/SPKR_mobile.svg"
import FeaturedSpeakerCard from "./featuredSpeakerCard"
import VerticalSectionTitle from "./verticalSectionTitle"

const SectionFeaturedSpeakers = ({ headingTag: HeadingTag = "p" }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          speaker1: file(
            relativePath: {
              eq: "speakers/202404_Ascend_Website_SpeakerHeadshotPhotos_RickGawenda.jpg"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          speaker2: file(
            relativePath: {
              eq: "speakers/202403_Ascend_Website_SpeakerHeadshotPhotos_HeidiJannenga.jpg"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          speaker3: file(
            relativePath: {
              eq: "speakers/202403_Ascend_Website_SpeakerHeadshotPhotos_BrianGallagher.jpg"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          speaker4: file(
            relativePath: {
              eq: "speakers/202403_Ascend_Website_SpeakerHeadshotPhotos_KendellJno-Finn.jpg"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          speaker5: file(
            relativePath: {
              eq: "speakers/202403_Ascend_Website_SpeakerHeadshotPhotos_AshleyGlover.jpg"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const speaker1 = data.speaker1.childImageSharp.fluid
        const speaker2 = data.speaker2.childImageSharp.fluid
        const speaker3 = data.speaker3.childImageSharp.fluid
        const speaker4 = data.speaker4.childImageSharp.fluid
        const speaker5 = data.speaker5.childImageSharp.fluid

        return (
          <StyledFeaturedSpeakersSection>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <VerticalSectionTitle
                  svg={<SectionHeadingSvgSpkr />}
                  mobileSvg={<SectionHeadingSvgSpkrMobile />}
                />
                <div className="small-12 large-3 cell">
                  <SectionTitle>Previous Featured Speakers</SectionTitle>
                  <SectionSubtitle>
                    The industry's best and brightest gather to speak at Ascend.
                  </SectionSubtitle>
                </div>
                <SpeakerCardContainer>
                  <FeaturedSpeakerCard
                    image={speaker1}
                    name="Rick Gawenda"
                    credentials="PT, PhD, OCS"
                    title="Founder and President"
                    association="Gawenda Semiars & Consulting, Inc."
                  />
                  <FeaturedSpeakerCard
                    image={speaker2}
                    name="Heidi Jannenga"
                    credentials="PT, DPT, ATC"
                    title="Co-Founder and Chief Clinical Officer "
                    association="WebPT"
                  />
                  <FeaturedSpeakerCard
                    image={speaker3}
                    name="Brian Gallagher"
                    credentials="PT"
                    title="President and Founder"
                    association="MEG Business Management"
                  />
                  <FeaturedSpeakerCard
                    image={speaker4}
                    name="Kendell Jno-Finn"
                    credentials="PT, DPT"
                    title="Founder and President"
                    association="M3 Performance and Physical Therapy"
                  />
                  <FeaturedSpeakerCard
                    image={speaker5}
                    name="Ashley Glover"
                    credentials=""
                    title="CEO"
                    association="WebPT"
                  />
                </SpeakerCardContainer>
              </div>
            </div>
          </StyledFeaturedSpeakersSection>
        )
      }}
    />
  )
}

export default SectionFeaturedSpeakers
const StyledFeaturedSpeakersSection = styled.section`
  @media screen and (max-width: 640px) {
    padding: 30px 0;
  }
`
const SpeakerCardContainer = styled.div.attrs({
  className: "small-12 large-8 cell",
})`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const SectionTitle = styled.h3`
  @media screen and (max-width: 640px) {
    margin-top: 20px;
    font-size: 22px;
    line-height: 1.3;
  }
`
const SectionSubtitle = styled.p`
  @media screen and (max-width: 640px) {
    margin-top: 20px;
  }
`
