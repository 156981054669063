import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const FeaturedSpeakerCard = (props) => {
  const { name, image, title, association, credentials } = props
  return (
    <StyledFeaturedSpeakerCard>
      <ImageContainer>
        <Img fluid={image} />
      </ImageContainer>
      <Name>{name}</Name>
      <Credentials>{credentials}</Credentials>
      <Title>{title}</Title>
      <Association>{association}</Association>
    </StyledFeaturedSpeakerCard>
  )
}

export default FeaturedSpeakerCard

const StyledFeaturedSpeakerCard = styled.div`
  width: 150px;
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  flex-wrap: wrap;

  p {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
`
const ImageContainer = styled.div`
  margin-bottom: 20px;
`
const Name = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 120%;
`
const Credentials = styled.p``
const Title = styled.p``
const Association = styled.p``
