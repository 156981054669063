import React from "react"
import styled from "styled-components"

const SectionIconStats = ({ iconStatArray, sectionTitle }) => {
  return (
    <StyledIconStats>
      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <div className="small-12 large-8 large-offset-2 cell text-center">
            <p className="section-title">{sectionTitle}</p>
          </div>
          <div className="small-12 cell">
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                {iconStatArray &&
                  iconStatArray.length > 0 &&
                  iconStatArray.map((iconStat) => (
                    <StatContainer key={iconStat.statTitle}>
                      <Icon>{iconStat.icon}</Icon>
                      <IconStatTitle>{iconStat.statTitle}</IconStatTitle>
                      <IconStatBody fontSize={iconStat.fontSize}>
                        {iconStat.statDescription}
                      </IconStatBody>
                    </StatContainer>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledIconStats>
  )
}

export default SectionIconStats

const StyledIconStats = styled.section`
  background-color: #f9fafc;
  padding: 90px 0;
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 640px) {
    padding: 10px 0;
  }
`

const Icon = styled.div`
  margin-bottom: 8px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
const IconStatTitle = styled.p`
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 120%;
  margin-bottom: 8px;
`

const IconStatBody = styled.p`
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.fontSize}px;
  line-height: ${(props) => (props.fontSize === 32 ? 130 : 180)}%;
`
const StatContainer = styled.div.attrs({
  className: "small-12 medium-6 large-3",
})`
  margin-bottom: 0;
  padding-right: 0.9375rem;
  padding-left: 31px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 30px;
  }
`
